<script lang="ts" setup>
const config = useRuntimeConfig();
if (config.public.storeId == 'professional-store') {
  useAuthGuardRedirection();
}

// Navigation for default theme
const { loadNavigationElements } = useNavigation();
const { data } = useAsyncData('mainNavigation', () => {
  return loadNavigationElements({ depth: 2 });
});
provide('swNavigation-main-navigation', data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: 'footer-navigation',
});
const { data: footerData } = useAsyncData('mainFooterNavigation', () => {
  return loadFooterNavigationElements({ depth: 2 });
});

provide('swNavigation-footer-navigation', footerData);
</script>

<template>
  <div>
    <LayoutHeader
      :header-headline="$t('header.confirm.headline')"
      hide-cart-icon
    />
    <LayoutNotifications />
    <div class="checkout__content-layout">
      <slot />
    </div>
    <LayoutBreadcrumbs
      :breadcrumb-title="$t('header.confirm.headline')"
      breadcrumb-link="checkout"
      :show-arrow="true"
    />
    <LayoutFooter />
  </div>
</template>

<style lang="scss" scoped>
:deep(.checkout__header-layout) {
  .button-circle__wrapper {
    display: none;
  }
}
.checkout__content-layout {
  max-width: 1440px;
  margin: 0 auto;
}
</style>
